import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, Icon } from '@popmenu/common-ui';
import { Link as LinkIcon } from '@popmenu/web-icons';

import { withSnackbar } from '../../../utils/withSnackbar';
import { withStyles } from '../../../utils/withStyles';
import shareIconFormStyles from './styles';

import TextField from '../../../admin/shared/forms/TextField';

const ShareLinkForm = props => (
  <div>
    <TextField
      className={props.classes.textarea}
      multiline
      rows={4}
      value={props.socialShareMessage}
      variant="standard"
    />
    <CopyToClipboard
      onCopy={() => {
        props.showSnackbar('Link was copied to your clipboard to share!');
      }}
      text={props.socialShareMessage}
    >
      <Button startIcon={<Icon icon={LinkIcon} />} variant="contained">Copy link to clipboard</Button>
    </CopyToClipboard>
  </div>
);

ShareLinkForm.defaultProps = {
  socialShareMessage: '',
};

ShareLinkForm.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  socialShareMessage: PropTypes.string,
};

export default compose(
  withStyles(shareIconFormStyles),
  withSnackbar,
  connect(state => ({
    socialShareMessage: state.modals.socialShareMessage,
  }), {}),
)(ShareLinkForm);
